<template>
  <v-app dark>
    <cds-row>
      <cds-col
        cols="12"
        lg="10"
        offset-lg="1"
      >
        <TextBody1>
          <div v-if="error.statusCode === 404">
            {{ pageNotFound }}
          </div>
          <div v-else>
            {{ otherError }}
          </div>
          <div
            @click="redirectHome"
            class="link color--primary"
          >
            {{ $t('buttons.goHomePage') }}
          </div>
        </TextBody1>
      </cds-col>
    </cds-row>
  </v-app>
</template>

<script setup lang="ts">
// import { useHead } from '../.nuxt/imports';
import TextBody1 from '@Typography/TextBody1.vue';

const { $i18n } = useNuxtApp();

useHead({
  title: $i18n.t('errors.pageNotFound'),
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: $i18n.t('seo.description')
    },
    {
      hid: 'keywords',
      name: 'keywords',
      content: $i18n.t('seo.keywords')
    }
  ]
});

const pageNotFound = $i18n.t('errors.pageNotFound');
const otherError = $i18n.t('errors.errorOccurred');

function redirectHome() {
  const runtimeConfig = useRuntimeConfig();
  const url = process.client ? window.location.origin : runtimeConfig.public.baseUrl;
  window.location.href = url as string;
}
</script>

<style lang="scss" scoped>
.link {
  color: map-get($primaryColor, primary);
  font-weight: map-get($fontWeights, normal);
  display: block;
  min-width: max-content;
  text-align: left;
  cursor: pointer;
}
</style>
